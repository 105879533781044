import React from "react"
import { useIntl, FormattedMessage, FormattedHTMLMessage, Link } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import Img from "gatsby-image"


import Layout from '../components/layout'
import Newsletter from '../components/newsletter'
import SEO from "../components/seo"


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

export default props => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "site.menu.home" })}
      />
      <div className="header-bg header-bg-02">
        <div className="tip-container">
          <div className="tip"><FormattedMessage id="site.clickHold" /></div>
        </div>
      </div>
      <div className="container mx-auto px-8 sm:px-0 sm:w-3/4 md:w-2/3 xl:w-1/2 text-center my-24">
        <p className="typo-xxl mb-4 md:mb-8"><FormattedMessage id="home.title1" /><br /><FormattedMessage id="home.title2" /></p>
        <p className="mb-4 md:mb-8">
          <span className="typo-text-xl"><FormattedMessage id="home.about.text1" /> </span>
          <span className="typo-text-xl"><FormattedMessage id="home.about.text2" /></span>
        </p>
        <Link to="/contact" className="btn btn-black mr-3">
          <FormattedMessage id="site.contact" />
        </Link>
        <Link to="/about" className="btn btn-black-outline">
          <FormattedMessage id="site.readmore" />
        </Link>
      </div>

      <div className="container sm:px-10 md:px-0 mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 lg:gap-10 my-20 section-bg sm:rounded-4xl">
          <div className="p-10 lg:p-16 md:pr-0 md:col-span-2 lg:col-span-3">
            <h2 className="typo-xl mb-5"><FormattedMessage id="home.workshops.title" /></h2>

            <p className="typo-text-lg mb-5 lg:mb-10"><FormattedMessage id="home.workshops.content" /></p>
            <Link to="/workshops" className="btn btn-green"><FormattedMessage id="home.workshops.button" /></Link>
          </div>

          <div className="px-10 md:p-5 lg:p-0 md:col-span-2">
            <Img className="rounded-4xl md:rounded-tr-4xl md:rounded-br-4xl" fluid={props.data.imgWorkshop.childImageSharp.fluid} />
          </div>

        </div>
      </div>


      <div className="container sm:px-10 md:px-0 mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 lg:gap-10 my-20 section-bg sm:rounded-4xl">
          <div className="p-10 lg:p-16 md:pr-0 md:col-span-2 lg:col-span-3">
            <h2 className="typo-xl mb-5"><FormattedMessage id="home.events.title" /></h2>

            <p className="typo-text-lg mb-5 lg:mb-10"><FormattedMessage id="home.events.content" /></p>
            <Link to="/events" className="btn btn-green"><FormattedMessage id="home.events.button" /></Link>
          </div>

          <div className="px-10 md:p-5 lg:p-0 md:col-span-2">
            <Img className="rounded-4xl md:rounded-tr-4xl md:rounded-br-4xl" fluid={props.data.imageEvents.childImageSharp.fluid} />
          </div>

        </div>
      </div>

      <div className="container px-10 md:px-0 mx-auto">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 md:gap-10 my-20">
          <div><Img className="rounded-4xl" fluid={props.data.img01.childImageSharp.fluid} /></div>
          <div><Img className="rounded-4xl" fluid={props.data.img02.childImageSharp.fluid} /></div>
          <div><Img className="rounded-4xl" fluid={props.data.img03.childImageSharp.fluid} /></div>
          <div><Img className="rounded-4xl" fluid={props.data.img04.childImageSharp.fluid} /></div>
          <div><Img className="rounded-4xl" fluid={props.data.img05.childImageSharp.fluid} /></div>
          <div><Img className="rounded-4xl" fluid={props.data.img06.childImageSharp.fluid} /></div>
          <div><Img className="rounded-4xl" fluid={props.data.img07.childImageSharp.fluid} /></div>
          <div><Img className="rounded-4xl" fluid={props.data.img08.childImageSharp.fluid} /></div>
        </div>
      </div>


      <div className="container sm:px-10 md:px-0 mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 lg:gap-10 my-20 section-bg sm:rounded-4xl">
          <div className="p-10 lg:p-16 md:pr-0 md:col-span-2 lg:col-span-3">
            <h2 className="typo-xl mb-5"><FormattedMessage id="home.shop.title" /></h2>

            <p className="typo-text-lg mb-5 lg:mb-10"><FormattedMessage id="home.shop.content" /></p>
            <div className="btn btn-green"><FormattedHTMLMessage id="home.shop.button" /></div>
          </div>

          <div className="px-10 md:p-5 lg:p-0 md:col-span-2">
            <Img className="rounded-4xl md:rounded-tr-4xl md:rounded-br-4xl" fluid={props.data.imgProductsPoster1.childImageSharp.fluid} />
          </div>

        </div>
      </div>
      <div className="bg-img-09 w-full">
        <Newsletter />
      </div>

    </Layout>
  )
}

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    imgWorkshop: file(relativePath: { eq: "metsiktoit_workshop_kimchi_03.png" }) {
      ...fluidImage
    }
    imageEvents: file(relativePath: { eq: "metsiktoit_index_events.png" }) {
      ...fluidImage
    }
    imgProductsPoster1: file(relativePath: { eq: "metsiktoit_products_poster_01.png" }) {
      ...fluidImage
    }

    img01: file(relativePath: { eq: "metsiktoit_img_sq_01.png" }) {
      ...fluidImage
    }
    img02: file(relativePath: { eq: "metsiktoit_img_sq_02.png" }) {
      ...fluidImage
    }
    img03: file(relativePath: { eq: "metsiktoit_img_sq_03.png" }) {
      ...fluidImage
    }
    img04: file(relativePath: { eq: "metsiktoit_img_sq_19.png" }) {
      ...fluidImage
    }
    img05: file(relativePath: { eq: "metsiktoit_img_sq_08.png" }) {
      ...fluidImage
    }
    img06: file(relativePath: { eq: "metsiktoit_img_sq_26.png" }) {
      ...fluidImage
    }
    img07: file(relativePath: { eq: "metsiktoit_img_sq_05.png" }) {
      ...fluidImage
    }
    img08: file(relativePath: { eq: "metsiktoit_img_sq_44.png" }) {
      ...fluidImage
    }
  }
`

